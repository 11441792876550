import styled from '@emotion/styled';

export const Wrapper = styled.div(({ theme }) => ({
  background: theme.colors.main100,
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',

  [theme.mediaQueries.medium]: {
    flexDirection: 'row',
  },
}));

export const Content = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  font: theme.fonts.title,
  fontSize: 20,
  color: theme.colors.white,
  padding: theme.spacings.l,
  paddingRight: theme.spacings.xxl,

  [theme.mediaQueries.medium]: {
    paddingRight: theme.spacings.xl,
  },

  h2: {
    marginBottom: 0,
    fontSize: 34,
    textDecoration: 'none',
  },

  h3: {
    fontFamily: '"Chronicle Light", arial',
    fontWeight: 400,
    fontSize: 26,
    marginBottom: theme.spacings.m,
    textDecoration: 'none',
  },

  p: {
    marginTop: theme.spacings.m,
  },

  'p:last-of-type': {
    marginBottom: 0,
  },
}));

export const Picture = styled.picture<{ size?: string }>(({ theme, size }) => ({
  width: '100%',
  overflow: 'hidden',

  [theme.mediaQueries.medium]: {
    width: '50%',

    ...(size === 'small' && {
      width: `calc((100% - ${theme.spacings.xxl}px) / 3)`,
      minHeight: 200,
    }),
  },

  ...(size === 'large' && {
    width: '100%',
  }),

  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
}));

export const StyledLink = styled.a({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  flex: 1,
});

export const StyledArrow = styled.a(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: 48,
  height: 48,
  background: theme.colors.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  svg: {
    color: theme.colors.main100,
    width: 14,
    height: 28,
  },
}));
