import React from 'react';
import get from 'lodash/get';
import parseHtml from 'html-react-parser';
import { Text } from '@src/components/Text';
import { IETPromotion } from '@src/models/IETPromotion';
import { GridRow } from '@src/theme/Global.styled';
import { Banner } from '@src/components/Banner';

interface PromotionProps {
  block: IETPromotion;
}

const PromotionBlock = (props: PromotionProps) => {
  const fields = get(props, 'block.fields', {});
  return (
    <GridRow data-block-type={get(props, 'block.system.contentType', '')}>
      <Banner
        imgSrc={get(fields, 'photo.fields.umbracoFile', '')}
        description={fields.title}
        link={get(fields, 'link.url', '') || '#'}
        linkProps={{
          target: get(fields, 'link.target', '_self') || '_self',
        }}
      >
        <h3>{fields?.title}</h3>
        <Text>{parseHtml(fields?.htmlCopy || '')}</Text>
      </Banner>
    </GridRow>
  );
};

export default PromotionBlock;
