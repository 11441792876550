import React, { FC } from 'react';
import { default as Arrow } from 'public/arrow.svg';
import {
  Wrapper,
  Content,
  Picture,
  StyledLink,
  StyledArrow,
} from './Banner.styled';
import Image from 'next/image';
import styles from './styles.module.scss';
import Link from 'next/link';

type BannerProps = {
  imgSrc?: string;
  imgSize?: string;
  description?: string;
  link?: string;
  linkProps?: {
    target?: string;
  };
};

export const Banner: FC<BannerProps> = ({
  children,
  imgSrc,
  imgSize,
  description,
  link,
  linkProps,
}) => {
  return (
    <Wrapper>
      <Picture size={imgSize}>
        <Link href={link} passHref {...linkProps}>
          <StyledLink>
            <div className={styles.bannerImage}>
              <Image
                src={imgSrc}
                width={361}
                height={254}
                objectFit={'cover'}
                alt={description}
                loading="lazy"
              />
            </div>
          </StyledLink>
        </Link>
      </Picture>
      {link ? (
        <>
          <Content>{children}</Content>
          <StyledArrow href={link} aria-label="view" {...linkProps}>
            <Arrow />
          </StyledArrow>
        </>
      ) : (
        <Content>{children}</Content>
      )}
    </Wrapper>
  );
};
